import axios from 'axios'
import Auth from "./auth";
import jwt_decode from "jwt-decode";

const baseURL = process.env.REACT_APP_SERVICES_API_BASE;
const storeId = process.env.REACT_APP_STOREID;

const auth = new Auth();
auth.loadAuth();

const axiosService = axios.create({
    baseURL,
    headers: { 'StoreId': storeId, 'Authorization': `Bearer ${auth.authData?.access_token}` }    
});

axiosService.interceptors.request.use(async req => {
    auth.loadAuth();
    const data = jwt_decode(auth.authData.access_token);
    
    //If token is NOT yet expired then Request will be as is
    if (data.exp > Date.now() / 1000)
       return req;
    
    //here: token has expired so get refresh token and modify request accordingly
    const grantURL = localStorage.getItem('grantURL');
    
    const resp = await axios.post(`${grantURL}`, `grant_type=refresh_token&refresh_token=${auth.authData.refresh_token}`, { headers: { 'StoreId': storeId, 'Content-Type': 'application/x-www-form-urlencoded' } });
    auth.authData.sub = data.sub;
    auth.authData.access_token = resp.data.access_token;
    auth.authData.refresh_token = resp.data.refresh_token;
    auth.storeAuth();       
    req.headers.Authorization = `Bearer ${resp.data.access_token}`; //replace header token with new token
    
    return req;    
})


export default axiosService;