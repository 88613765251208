import React, { Component } from "react";

export default class PhoneButton extends Component {
  render() {
    return (
      <button
        onClick={this.props.onButtonClicked}
        className={this.props.btnClass}
      >
        {this.props.btnText}
      </button>
    );
  }
}